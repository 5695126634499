<template>
  <div>
    <CrudCreate
      v-bind="$store.state.stone_model[_modelName]"
      :extendPostData="_extendPostData"
      :label="_label"
      :urlModelName="_urlModelName"
    ></CrudCreate>
  </div>
</template>

<script>
export default {
  computed: {
    _modelName() {
      return this.$route.meta.modelName;
    },
    _extendPostData() {
      return this.$route.meta.params ? this.$route.meta.params : {};
    },
    _label() {
      let _label;
      if (this.$route.meta.label) {
        _label = this.$route.meta.label;
      } else {
        _label = this.$store.state.stone_model[this._modelName].label;
      }
      return _label;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
  },
  mounted() {},
};
</script>